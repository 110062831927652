import React from 'react';

class Times extends React.Component {

    render() {
        return(
            <section className="picktime">
                <ul>
                    {/* <li><a href=''>9:00 AM</a></li>
                    <li><a href=''>9:30 AM</a></li>
                    <li><a href=''>10:15 AM</a></li>
                    <li><a href=''>1:00 AM</a></li>
                    <li><a href=''>2:30 AM</a></li>
                    <li><a href=''>3:45 AM</a></li>
                    <li><a href=''>4:14 AM</a></li>
                    <li><a href=''>5:30 AM</a></li> */}
                </ul>
            </section>
        )
    }
}

export default Times