import React from 'react';

class AppointmentPage extends React.Component {

    render() {
        return (
            <div>
                <h2>Faucibus Purus in Massa</h2>
                <p className="main-text">Nibh praesent tristique magna sit amet purus gravida quis blandit turpis cursus in hac habitasse</p>
                <h2>List Appointments</h2>
            </div>
        )
    }
}

export default AppointmentPage;