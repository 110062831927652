import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <footer>
                <p>© 2019 rjg</p>
            </footer>
        )
    }
}

export default Footer;

